import SuccessView from "./SuccessView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import "./Home.css";

export default function Home() {
  const session = useSessionContext();

  if (session.loading) {
    return <div>Loading...</div>;
  }

  if (session.doesSessionExist === false) {
    return <div>Session does not exist</div>;
  }
  console.log("session " + JSON.stringify(session));
  return (
    <div className="z-20" id="home-container">
      <SuccessView userId={session.userId} />
    </div>
  );
}
