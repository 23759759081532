import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function ProductDetailsImageswithDescription() {
  let navigate = useNavigate();
  const { search } = useLocation();
  var myimage = search.split("=")[1];
  console.log(search.split("=")[1]);
  return (
    <>
      {/* Product Details Section: Images With Description */}
      <div className="bg-white dark:text-gray-100 dark:bg-gray-900">
        <div className="container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
          <div
            onClick={() => window.history.back()}
            className="mb-8 cursor-pointer"
          >
            Back
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-16">
            {/* Images */}
            <div>
              <img
                src={`https://flowbite.s3.amazonaws.com/docs/gallery/square/${myimage}`}
                alt="Product Image"
              />
              <div className="grid grid-cols-4 gap-2 mt-2">
                <a
                  href="#"
                  className="transition ease-out opacity-50 hover:opacity-100"
                >
                  <img
                    src="https://cdn.tailkit.com/media/placeholders/photo-76w_eDO1u1E-200x200.jpg"
                    alt="Product Image 1"
                  />
                </a>
                <a
                  href="#"
                  className="transition ease-out opacity-50 hover:opacity-100"
                >
                  <img
                    src="https://cdn.tailkit.com/media/placeholders/photo-aDZ5YIuedQg-200x200.jpg"
                    alt="Product Image 2"
                  />
                </a>
                <a
                  href="#"
                  className="transition ease-out opacity-50 hover:opacity-100"
                >
                  <img
                    src="https://cdn.tailkit.com/media/placeholders/photo-PGTO_A0eLt4-200x200.jpg"
                    alt="Product Image 3"
                  />
                </a>
                <a
                  href="#"
                  className="transition ease-out opacity-50 hover:opacity-100"
                >
                  <img
                    src="https://cdn.tailkit.com/media/placeholders/photo-1zBPoVwS_Jo-200x200.jpg"
                    alt="Product Image 4"
                  />
                </a>
              </div>
            </div>
            {/* END Images */}

            {/* Product Info */}
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-4">
                <div>
                  <div className="uppercase text-blue-600 tracking-wide text-xs font-semibold mb-1 dark:text-blue-400">
                    Shoes
                  </div>
                  <a
                    href="#"
                    className="block font-semibold hover:text-gray-500 dark:hover:text-gray-300"
                  >
                    Air Sneakers
                  </a>
                </div>
                <div className="text-orange-500 text-left sm:text-right">
                  <svg
                    className="hi-mini hi-star inline-block w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <svg
                    className="hi-mini hi-star inline-block w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <svg
                    className="hi-mini hi-star inline-block w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <svg
                    className="hi-mini hi-star inline-block w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <svg
                    className="hi-mini hi-star inline-block w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div className="prose prose-indigo mb-4 dark:prose-invert">
                <p>
                  Maecenas ultrices, justo vel imperdiet gravida, urna ligula
                  hendrerit nibh, ac cursus nibh sapien in purus. Mauris
                  tincidunt tincidunt turpis in porta. Integer fermentum
                  tincidunt auctor.
                </p>
                <p>
                  Consectetur adipiscing elit. Maecenas ultrices, justo vel
                  imperdiet gravida, urna ligula hendrerit nibh, ac cursus nibh
                  sapien in purus. Mauris tincidunt tincidunt turpis in porta
                  integer.
                </p>
                <p>
                  Urna ligula hendrerit nibh, ac cursus nibh sapien in purus.
                  Mauris tincidunt tincidunt turpis in porta. Integer fermentum
                  tincidunt.
                </p>
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-center space-x-2 p-3 bg-gray-100 rounded-lg dark:bg-gray-700">
                  <button
                    type="button"
                    className="inline-block h-5 w-5 rounded-lg hover:opacity-75 active:opacity-100 focus:outline-none bg-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50"
                  />
                  <button
                    type="button"
                    className="inline-block h-5 w-5 rounded-lg hover:opacity-75 active:opacity-100 focus:outline-none bg-pink-400 focus:ring focus:ring-pink-400 focus:ring-opacity-50"
                  />
                  <button
                    type="button"
                    className="inline-block h-5 w-5 rounded-lg hover:opacity-75 active:opacity-100 focus:outline-none bg-emerald-400 focus:ring focus:ring-emerald-400 focus:ring-opacity-50"
                  />
                  <button
                    type="button"
                    className="inline-block h-5 w-5 rounded-lg hover:opacity-75 active:opacity-100 focus:outline-none bg-rose-400 focus:ring focus:ring-rose-400 focus:ring-opacity-50"
                  />
                  <button
                    type="button"
                    className="inline-block h-5 w-5 rounded-lg hover:opacity-75 active:opacity-100 focus:outline-none bg-sky-400 focus:ring focus:ring-sky-400 focus:ring-opacity-50"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="font-medium text-gray-600 dark:text-gray-400">
                    $153
                  </div>
                  <div className="inline-flex items-center">
                    <button
                      type="button"
                      className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-2 py-1 leading-5 text-sm border-transparent bg-transparent text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-transparent dark:bg-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                    >
                      <svg
                        className="hi-mini hi-minus-small inline-block w-5 h-5 -mx-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                      </svg>
                    </button>
                    <span className="text-sm px-2.5">1</span>
                    <button
                      type="button"
                      className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-2 py-1 leading-5 text-sm border-transparent bg-transparent text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-transparent dark:bg-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                    >
                      <svg
                        className="hi-mini hi-plus-small inline-block w-5 h-5 -mx-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
                  <button
                    type="button"
                    className="border-b-2 justify-center items-center space-x-2 border font-semibold rounded-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                  >
                    <svg
                      className="hi-mini hi-heart inline-block w-5 h-5 opacity-50"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
                    </svg>
                    <span>Favorite</span>
                  </button>
                  <button
                    type="button"
                    className="sm:grow inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                  >
                    <svg
                      className="hi-mini hi-shopping-bag inline-block w-5 h-5 opacity-50"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 5v1H4.667a1.75 1.75 0 00-1.743 1.598l-.826 9.5A1.75 1.75 0 003.84 19H16.16a1.75 1.75 0 001.743-1.902l-.826-9.5A1.75 1.75 0 0015.333 6H14V5a4 4 0 00-8 0zm4-2.5A2.5 2.5 0 007.5 5v1h5V5A2.5 2.5 0 0010 2.5zM7.5 10a2.5 2.5 0 005 0V8.75a.75.75 0 011.5 0V10a4 4 0 01-8 0V8.75a.75.75 0 011.5 0V10z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Add to Cart</span>
                  </button>
                  <button
                    type="button"
                    className="sm:grow inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                  >
                    <svg
                      className="hi-mini hi-shopping-bag inline-block w-5 h-5 opacity-50"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 5v1H4.667a1.75 1.75 0 00-1.743 1.598l-.826 9.5A1.75 1.75 0 003.84 19H16.16a1.75 1.75 0 001.743-1.902l-.826-9.5A1.75 1.75 0 0015.333 6H14V5a4 4 0 00-8 0zm4-2.5A2.5 2.5 0 007.5 5v1h5V5A2.5 2.5 0 0010 2.5zM7.5 10a2.5 2.5 0 005 0V8.75a.75.75 0 011.5 0V10a4 4 0 01-8 0V8.75a.75.75 0 011.5 0V10z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span onClick={() => navigate("/checkout")}>Buy</span>
                  </button>
                </div>
              </div>
            </div>
            {/* END Product Info */}
          </div>
        </div>
      </div>
      {/* END Product Details Section: Images With Description */}
    </>
  );
}
