import { useNavigate } from "react-router-dom";
export default function ContactSimple() {
  let navigate = useNavigate();

  async function domynav() {
    navigate("/");
  }
  async function handleSubmit(e) {
    e.preventDefault();
    // Create the email body

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Email: `${e.currentTarget.elements.email.value}`,
      }),
    };

    try {
      await fetch(
        "https://www.casabarber.com/api/nirvanacontact",
        requestOptions,
      )
        .then(console.log("testing log line"))
        .then(
          setTimeout(() => {
            domynav();
          }, 500),
        );
    } catch (error) {
      console.log("There was an error", error);
    }
  }

  //
  return (
    <>
      {/* Page Container */}
      <div
        id=""
        className="relative mx-auto flex w-full flex-col dark:bg-gray-900 dark:text-gray-100 m-10"
      >
        {/* Page Content */}
        <main id="" className="flex max-w-full flex-auto flex-col">
          {/* Contact Section: With Image Cover Brand */}
          <div
            className="bg-cover"
            style={{
              backgroundImage:
                'url("https://cdn.tailkit.com/media/placeholders/photo-wpU4veNGnHg-1280x800.jpg")',
            }}
          >
            <div className="bg-blue-900 bg-opacity-95">
              <div className="container mx-auto px-4 lg:py-32 xl:max-w-7xl">
                <div className="flex flex-col space-y-16 lg:flex-row lg:items-center lg:space-x-16 lg:space-y-0">
                  {/* Heading with Company Info */}
                  <div className="lg:w-2/5">
                    <div className="mb-1 text-sm font-bold uppercase tracking-wider text-blue-300">
                      We try to reply in 24hrs
                    </div>
                    <h2 className="mb-4 text-4xl font-black text-white">
                      Get in touch
                    </h2>
                    <h3 className="text-xl font-medium leading-relaxed text-gray-200">
                      Contact us! We will get back to you as soon as possible.
                    </h3>
                    <h4 className="mb-4 mt-10 font-semibold uppercase tracking-wider text-gray-100">
                      NYC Nirvana
                    </h4>
                    <div className="leading-relaxed text-gray-300">
                      New York
                      <br />
                    </div>
                  </div>

                  {/* Contact Form */}
                  <div className="flex flex-col overflow-hidden rounded-xl bg-white/10 p-2 shadow-sm dark:text-gray-100 lg:mx-auto lg:w-1/2">
                    <form
                      className="space-y-6 rounded-lg bg-white p-5 dark:bg-gray-800 md:p-10 text-black"
                      onSubmit={handleSubmit}
                    >
                      <div className="space-y-1">
                        <label htmlFor="email" className="font-medium">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="block w-full rounded-lg border border-gray-200 px-5 py-3 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500"
                        />
                      </div>
                      <button
                        type="submit"
                        className="inline-flex w-full items-center justify-center space-x-2 rounded-lg border border-blue-700 bg-blue-700 px-8 py-4 font-semibold leading-6 text-white hover:border-blue-600 hover:bg-blue-600 hover:text-white focus:ring focus:ring-blue-400 focus:ring-opacity-50 active:border-blue-700 active:bg-blue-700 dark:focus:ring-blue-400 dark:focus:ring-opacity-90"
                      >
                        <svg
                          className="hi-mini hi-paper-airplane inline-block h-5 w-5 opacity-50"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z" />
                        </svg>
                        <span>Send </span>
                      </button>
                    </form>
                  </div>
                  {/* END Contact Form */}
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* END Page Content */}
      </div>
      {/* END Page Container */}
    </>
  );
}
