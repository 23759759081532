import CheckoutForm from "./checkOutForm";

export default function CheckoutSplitPayment() {
  return (
    <>
      {/* Checkout Section: Split Payment */}
      <div className="relative bg-gray-50 min-h-screen flex items-center overflow-hidden dark:text-gray-100 dark:bg-gray-900">
        <div className="absolute hidden lg:block top-0 right-0 bottom-0 w-full lg:w-1/2 bg-white shadow-lg dark:bg-gray-800" />
        <div className="relative container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-0">
            {/* Shopping Cart Overview Section */}
            <div className="flex flex-col justify-between lg:px-14 xl:px-28 space-y-6">
              <div className="space-y-6">
                <div className="text-center lg:text-left">
                  <a
                    href="/"
                    className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 text-sm border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                  >
                    <svg
                      className="hi-mini hi-arrow-left inline-block w-4 h-4 opacity-50"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Back to store</span>
                  </a>
                </div>
                <div className="text-center lg:text-left">
                  <p className="text-lg text-gray-600 font-medium mb-1 dark:text-gray-400">
                    Pay Store Inc
                  </p>
                  <p className="text-3xl font-bold">$1,157.00</p>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center space-x-3 p-4 rounded-lg bg-gray-100 hover:bg-gray-200 hover:bg-opacity-50 dark:bg-gray-800 dark:hover:bg-gray-700/50">
                    <img
                      src="https://cdn.tailkit.com/media/placeholders/photo-PDX_a_82obo-100x100.jpg"
                      className="flex-none w-12 h-12 rounded-lg"
                      alt="Product Image"
                    />
                    <div className="grow">
                      <a
                        href="#"
                        className="font-semibold text-blue-600 hover:text-blue-400 dark:text-gray-400 dark:hover:text-gray-300"
                      >
                        Wireless Headphones
                      </a>
                      <p className="mt-0.5 text-xs font-medium text-gray-600 dark:text-gray-400">
                        SKU0058745
                      </p>
                    </div>
                    <div className="flex-none text-right font-medium">
                      $129.00
                    </div>
                  </div>
                  <div className="flex items-center space-x-3 p-4 rounded-lg bg-gray-100 hover:bg-gray-200 hover:bg-opacity-50 dark:bg-gray-800 dark:hover:bg-gray-700/50">
                    <img
                      src="https://cdn.tailkit.com/media/placeholders/photo-2cFZ_FB08UM-100x100.jpg"
                      className="flex-none w-12 h-12 rounded-lg"
                      alt="Product Image"
                    />
                    <div className="grow">
                      <a
                        href="#"
                        className="font-semibold text-blue-600 hover:text-blue-400 dark:text-gray-400 dark:hover:text-gray-300"
                      >
                        Smart Watch Pro
                      </a>
                      <p className="mt-0.5 text-xs font-medium text-gray-600 dark:text-gray-400">
                        SKU0098741
                      </p>
                    </div>
                    <div className="flex-none text-right font-medium">
                      $349.00
                    </div>
                  </div>
                  <div className="flex items-center space-x-3 p-4 rounded-lg bg-gray-100 hover:bg-gray-200 hover:bg-opacity-50 dark:bg-gray-800 dark:hover:bg-gray-700/50">
                    <img
                      src="https://cdn.tailkit.com/media/placeholders/photo-7OFnb7NOvjw-100x100.jpg"
                      className="flex-none w-12 h-12 rounded-lg"
                      alt="Product Image"
                    />
                    <div className="grow">
                      <a
                        href="#"
                        className="font-semibold text-blue-600 hover:text-blue-400 dark:text-gray-400 dark:hover:text-gray-300"
                      >
                        Tablet Plus 1TB
                      </a>
                      <p className="mt-0.5 text-xs font-medium text-gray-600 dark:text-gray-400">
                        SKU0096236
                      </p>
                    </div>
                    <div className="flex-none text-right font-medium">
                      $679.00
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-xs text-gray-500 font-medium space-y-3 text-center lg:text-left">
                <p>
                  Powered by{" "}
                  <a
                    href="#"
                    className="text-blue-600 hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-300"
                  >
                    Store Inc
                  </a>
                  <span className="mx-1">|</span>
                  <a
                    href="#"
                    className="text-gray-600 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    Terms
                  </a>
                  <a
                    href="#"
                    className="ml-2 text-gray-600 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    Privacy
                  </a>
                </p>
              </div>
            </div>
            {/* END Shopping Cart Overview Section */}

            <CheckoutForm />
          </div>
        </div>
      </div>
      {/* END Checkout Section: Split Payment */}
    </>
  );
}
