import ThirdPartyEmailPassword, {
  Google,
  Github,
  Apple,
  Twitter,
} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

const isProd = process.env.NODE_ENV == "production" ? true : false;

console.log("isprod " + isProd);

export function getApiDomain() {
  const apiPort = isProd ? process.env.REACT_APP_API_PORT : 3001;
  const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
  console.log("api url " + apiUrl + " " + process.env.REACT_APP_API_URL);
  return apiUrl;
}

export function getWebsiteDomain() {
  const websitePort = isProd ? process.env.REACT_APP_WEBSITE_PORT : 3000;
  const websiteUrl =
    process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
  console.log(
    "website domain " + websiteUrl + " " + process.env.REACT_APP_WEBSITE_URL,
  );
  return websiteUrl;
}

export const SuperTokensConfig = {
  appInfo: {
    appName: "NYC Nirvana",
    apiDomain: getApiDomain(),
    websiteDomain: getWebsiteDomain(),
    apiBasePath: isProd ? "/apinirvana/auth" : "/auth",
  },
  // recipeList contains all the modules that you want to
  // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
  recipeList: [
    ThirdPartyEmailPassword.init({
      // onHandleEvent: async (context) => {
      //   console.log("context action " + context.action);
      //   if (context.action === "SESSION_ALREADY_EXISTS") {
      //     throw new Error("session already exists");
      //   } else {
      //     if (context.action === "SUCCESS") {
      //       if (
      //         context.isNewRecipeUser &&
      //         context.user.loginMethods.length === 1
      //       ) {
      //         console.log("to do stuff");
      //       } else {
      //         console.log("else sign in stuff");
      //       }
      //     }
      //   }
      // },
      signInAndUpFeature: {
        providers: [Github.init()],
      },
    }),
    Session.init(),
  ],
};

export const recipeDetails = {
  docsLink: "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};

export const PreBuiltUIList = [ThirdPartyEmailPasswordPreBuiltUI];

export const ComponentWrapper = (props: {
  children: JSX.Element;
}): JSX.Element => {
  return props.children;
};
