import { Navigate, useNavigate } from "react-router-dom";

export default function Gallery() {
  var navigate = useNavigate();
  var imagenames = [
    "image.jpg",
    "image-1.jpg",
    "image-2.jpg",
    "image-3.jpg",
    "image-4.jpg",
    "image-5.jpg",
    "image-6.jpg",
    "image-7.jpg",
    "image-8.jpg",
    "image-9.jpg",
    "image-10.jpg",
    "image-11.jpg",
  ];
  const items = imagenames.map((elem, index) => {
    return (
      <div
        onClick={(e) => navigate(`/detail?product_name=${e.target.name}`)}
        className={"px-16 py-4"}
        key={imagenames[index]}
      >
        <img
          className="h-auto max-w-full rounded-lg cursor-pointer"
          src={`https://flowbite.s3.amazonaws.com/docs/gallery/square/${elem}`}
          name={elem}
          alt=""
        />
      </div>
    );
  });

  return (
    <div className="">
      <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-8">{items}</div>
    </div>
  );
}
