import {
  useSessionContext,
  doesSessionExist,
  signOut,
} from "supertokens-auth-react/recipe/session";
import { useNavigate } from "react-router-dom";
import Contact from "./contact";
import { useState } from "react";
import Gallery from "./gallery";
export default function Landing() {
  // const sessionContext = useSessionContext();
  const [isLoggy, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  // if (sessionContext.loading === true) {
  //     console.log("sess")
  //   return null;
  // }

  async function logoutClicked() {
    await signOut();
    navigate("/");
  }

  async function isSession() {
    var myvar = await doesSessionExist();
    setIsLoggedIn(myvar);
  }

  isSession();

  const logButton = isLoggy ? (
    <div
      onClick={() => logoutClicked()}
      className={
        "absolute p-4 text-white top-1 right-2 bg-orange-300 rounded cursor-pointer"
      }
    >
      Sign Out
    </div>
  ) : (
    <a
      href={"/myauth"}
      className={"absolute p-4 text-white top-1 right-2 bg-orange-300 rounded"}
    >
      Sign In
    </a>
  );

  return (
    <div className="" id="">
      <div className={"flex flex-col justify-center p-8"}>
        <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            NYC Nirvana
          </span>{" "}
          Better Living Through Technology
        </h1>
        <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
          Here at NYC Nirvana we believe that the right technology and human
          capital can unlock long-term value and drive economic growth.
        </p>
      </div>
      {logButton}
      <div className={"p-10 pt-16 mt-5"}>
        <Contact />
      </div>
      <div className={"relative mx-auto flex flex-col"}>
        <h1 className="mb-4 flex justify-center text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          Shop Accessories to your Nirvava
        </h1>
        <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
          Our Focus is your Zen. We believe in providing the best products to
          our customers to help them achieve their Nirvana.
        </p>
        <a
          href="#"
          className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
        >
          Learn more
          <svg
            className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
      <Gallery />
      <div className={"text-3xl text-black "}>
        Come grab some rest and relaxation
        <img src={"recliners.png"} className={"mx-auto"} />
      </div>
    </div>
  );
}
