import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { recipeDetails } from "../config";
import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import TextEffect from "./pagescroll4";
import { motion } from "framer-motion";
import { FaUserGear } from "react-icons/fa6";
import DragAndDrop from "./draganddrop";
import axios from "axios";
import { getApiDomain } from "../config";
import {
  BlogsIcon,
  CelebrateIcon,
  GuideIcon,
  SeparatorLine,
  SignOutIcon,
} from "../assets/images";

interface ILink {
  name: string;
  icon: string;
}

function MyComponent() {
  return (
    <motion.div
      className="box"
      animate={{
        scale: [1, 2, 2, 1, 1],
        rotate: [0, 0, 180, 180, 0],
        borderRadius: ["0%", "0%", "50%", "50%", "0%"],
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 1,
      }}
    />
  );
}

export default function SuccessView(props: { userId: string }) {
  let userId = props.userId;
  const [isOpen, setopen] = useState(true);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();
  console.log("userId " + userId);

  const requestOptions = {
    body: props.userId,
  };

  if (!userId) {
    axios
      .post("https://www.casabarber.com/api/nirvanauser", requestOptions)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function logoutClicked() {
    console.log("logout clicked");
    await signOut();
    navigate("/");
  }

  const showmodal = isOpen ? "flex" : "hidden";

  return (
    <>
      <div className="z-10">
        <div
          id="successModal"
          tabIndex={-1}
          aria-hidden="true"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            console.log("toggled");
            setopen(!isOpen);
          }}
          className={`${showmodal} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full`}
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <button
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="successModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Success</span>
              </div>
              <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                Successful login.
              </p>
              <button
                data-modal-toggle="successModal"
                type="button"
                className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* Page Container */}
        <div
          id="page-container"
          className="flex flex-col mx-auto w-full min-h-screen min-w-[320px] bg-gray-100 dark:text-gray-100 dark:bg-gray-800/50"
        >
          {/* Page Header */}
          <header
            id="page-header"
            className="flex flex-none items-center z-1 bg-blue-900"
          >
            <div className="container xl:max-w-7xl mx-auto px-4 lg:px-8">
              <div className="flex justify-between py-4">
                {/* Left Section */}
                <div className="flex items-center space-x-2 lg:space-x-6 dark">
                  {/* Logo */}
                  <a
                    href="/"
                    className="group inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-gray-900 hover:text-gray-600 dark:text-gray-100 dark:hover:text-gray-300"
                  >
                    <svg
                      className="hi-mini hi-cube-transparent inline-block w-5 h-5 text-blue-600 transition group-hover:scale-110 dark:text-blue-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.638 1.093a.75.75 0 01.724 0l2 1.104a.75.75 0 11-.724 1.313L10 2.607l-1.638.903a.75.75 0 11-.724-1.313l2-1.104zM5.403 4.287a.75.75 0 01-.295 1.019l-.805.444.805.444a.75.75 0 01-.724 1.314L3.5 7.02v.73a.75.75 0 01-1.5 0v-2a.75.75 0 01.388-.657l1.996-1.1a.75.75 0 011.019.294zm9.194 0a.75.75 0 011.02-.295l1.995 1.101A.75.75 0 0118 5.75v2a.75.75 0 01-1.5 0v-.73l-.884.488a.75.75 0 11-.724-1.314l.806-.444-.806-.444a.75.75 0 01-.295-1.02zM7.343 8.284a.75.75 0 011.02-.294L10 8.893l1.638-.903a.75.75 0 11.724 1.313l-1.612.89v1.557a.75.75 0 01-1.5 0v-1.557l-1.612-.89a.75.75 0 01-.295-1.019zM2.75 11.5a.75.75 0 01.75.75v1.557l1.608.887a.75.75 0 01-.724 1.314l-1.996-1.101A.75.75 0 012 14.25v-2a.75.75 0 01.75-.75zm14.5 0a.75.75 0 01.75.75v2a.75.75 0 01-.388.657l-1.996 1.1a.75.75 0 11-.724-1.313l1.608-.887V12.25a.75.75 0 01.75-.75zm-7.25 4a.75.75 0 01.75.75v.73l.888-.49a.75.75 0 01.724 1.313l-2 1.104a.75.75 0 01-.724 0l-2-1.104a.75.75 0 11.724-1.313l.888.49v-.73a.75.75 0 01.75-.75z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>NYC Nirvana</span>
                  </a>
                  {/* END Logo */}

                  {/* Desktop Navigation */}
                  <div className="hidden lg:flex items-center space-x-2">
                    <a
                      href="/"
                      className="group text-sm font-medium flex items-center space-x-2 px-3 py-2 rounded-lg text-blue-600 border border-blue-100 bg-blue-50 dark:text-white dark:bg-white/10 dark:border-transparent"
                    >
                      <span>Dashboard</span>
                    </a>
                    <a
                      href="#"
                      className="group text-sm font-medium flex items-center space-x-2 px-3 py-2 rounded-lg text-gray-800 border border-transparent hover:text-blue-600 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-white/10 dark:active:border-white/20"
                    >
                      <span>Customers</span>
                    </a>
                    <a
                      href="/checkout"
                      className="group text-sm font-medium flex items-center space-x-2 px-3 py-2 rounded-lg text-gray-800 border border-transparent hover:text-blue-600 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-white/10 dark:active:border-white/20"
                    >
                      <span>Checkout</span>
                    </a>
                    <a
                      href="#"
                      className="group text-sm font-medium flex items-center space-x-2 px-3 py-2 rounded-lg text-gray-800 border border-transparent hover:text-blue-600 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-white/10 dark:active:border-white/20"
                    >
                      <span>Sales</span>
                    </a>
                  </div>
                  {/* END Desktop Navigation */}
                </div>
                {/* END Left Section */}

                {/* Right Section */}
                <div className="flex items-center space-x-2">
                  {/* User Dropdown */}
                  <Menu as="div" className="relative inline-block">
                    {/* Dropdown Toggle Button */}
                    <div className="dark">
                      <Menu.Button className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 text-sm border-blue-200 bg-white text-blue-800 hover:border-blue-300 hover:text-blue-900 hover:shadow-sm focus:ring focus:ring-blue-300 focus:ring-opacity-25 active:border-blue-200 active:shadow-none dark:border-white/20 dark:bg-transparent dark:text-gray-100 dark:hover:border-blue-500 dark:hover:border-opacity-75 dark:hover:text-gray-50 dark:focus:ring-blue-600 dark:focus:ring-opacity-60 dark:active:border-blue-500 dark:active:border-opacity-50">
                        <span className=" sm:inline">
                          <FaUserGear className={"text-3xl"} />
                        </span>
                      </Menu.Button>
                    </div>
                    {/* END Dropdown Toggle Button */}

                    {/* Dropdown */}
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="opacity-0 scale-90"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-90"
                    >
                      <Menu.Items className="absolute right-0 origin-top-right z-10 mt-2 w-48 shadow-xl rounded-lg dark:shadow-gray-900 focus:outline-none">
                        <div className="bg-white ring-1 ring-black ring-opacity-5 rounded-lg divide-y divide-gray-100 dark:bg-gray-800 dark:divide-gray-700 dark:ring-gray-700">
                          <div className="p-2.5 space-y-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={`group text-sm font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent ${
                                    active
                                      ? "text-blue-800 bg-blue-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent"
                                      : "text-gray-700 hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600"
                                  }`}
                                >
                                  <span className="grow">Inbox</span>
                                  <div className="font-semibold inline-flex px-1.5 py-0.5 leading-4 text-xs rounded-full border border-blue-200 text-blue-700 bg-blue-100 dark:text-blue-50 dark:bg-blue-700 dark:border-blue-700">
                                    2
                                  </div>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={`group text-sm font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent ${
                                    active
                                      ? "text-blue-800 bg-blue-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent"
                                      : "text-gray-700 hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600"
                                  }`}
                                >
                                  <span className="grow">Notifications</span>
                                  <div className="font-semibold inline-flex px-1.5 py-0.5 leading-4 text-xs rounded-full border border-blue-200 text-blue-700 bg-blue-100 dark:text-blue-50 dark:bg-blue-700 dark:border-blue-700">
                                    5
                                  </div>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                          <div className="p-2.5 space-y-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={`group text-sm font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent ${
                                    active
                                      ? "text-blue-800 bg-blue-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent"
                                      : "text-gray-700 hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600"
                                  }`}
                                >
                                  <span className="grow">Account</span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={`group text-sm font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent ${
                                    active
                                      ? "text-blue-800 bg-blue-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent"
                                      : "text-gray-700 hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600"
                                  }`}
                                >
                                  <span className="grow">Settings</span>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                          <div className="p-2.5 space-y-1">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => logoutClicked()}
                                  className={`group text-sm font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent ${
                                    active
                                      ? "text-blue-800 bg-blue-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent"
                                      : "text-gray-700 hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600"
                                  }`}
                                >
                                  <span className="grow cursor-pointer">
                                    Sign out
                                  </span>
                                </div>
                              )}
                            </Menu.Item>
                          </div>
                        </div>
                      </Menu.Items>
                    </Transition>
                    {/* END Dropdown */}
                  </Menu>
                  {/* END User Dropdown */}

                  {/* Toggle Mobile Navigation */}
                  <div className="lg:hidden dark">
                    <button
                      onClick={() => setMobileNavOpen(!mobileNavOpen)}
                      type="button"
                      className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 text-sm border-blue-200 bg-white text-blue-800 hover:border-blue-300 hover:text-blue-900 hover:shadow-sm focus:ring focus:ring-blue-300 focus:ring-opacity-25 active:border-blue-200 active:shadow-none dark:border-white/20 dark:bg-transparent dark:text-gray-100 dark:hover:border-blue-500 dark:hover:border-opacity-75 dark:hover:text-gray-50 dark:focus:ring-blue-600 dark:focus:ring-opacity-60 dark:active:border-blue-500 dark:active:border-opacity-50"
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hi-solid hi-menu inline-block w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  {/* END Toggle Mobile Navigation */}
                </div>
                {/* END Right Section */}
              </div>

              {/* Mobile Navigation */}
              <div
                className={`lg:hidden dark ${mobileNavOpen ? "" : "hidden"}`}
              >
                <nav className="flex flex-col space-y-2 py-4 border-t dark:border-white/10">
                  <a
                    href="#"
                    className="group text-sm justify-end font-semibold flex items-center space-x-2 px-3 py-2 rounded-lg text-blue-600 border border-blue-50 bg-blue-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent"
                  >
                    <span>Dashboard</span>
                  </a>
                  <a
                    href="#"
                    className="group text-sm justify-end font-medium flex items-center space-x-2 px-3 py-2 rounded-lg text-gray-800 border border-transparent hover:text-blue-600 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-white/10 dark:active:border-white/20"
                  >
                    <span>Customers</span>
                  </a>
                  <a
                    href="#"
                    className="group text-sm justify-end font-medium flex items-center space-x-2 px-3 py-2 rounded-lg text-gray-800 border border-transparent hover:text-blue-600 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-white/10 dark:active:border-white/20"
                  >
                    <span>Projects</span>
                  </a>
                  <a
                    href="#"
                    className="group text-sm justify-end font-medium flex items-center space-x-2 px-3 py-2 rounded-lg text-gray-800 border border-transparent hover:text-blue-600 hover:bg-blue-50 active:border-blue-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-white/10 dark:active:border-white/20"
                  >
                    <span>Sales</span>
                  </a>
                </nav>
              </div>
              {/* END Mobile Navigation */}
            </div>
          </header>
          {/* END Page Header */}

          {/* Page Content */}
          <main
            id="page-content"
            className="flex flex-auto flex-col max-w-full"
          >
            {/* Page Heading */}
            <div className="dark bg-blue-900 text-gray-100 h-48">
              <div className="container xl:max-w-7xl mx-auto px-4 lg:px-8">
                <div className="flex items-center py-4 border-t border-white/10">
                  <div className="grow">
                    <h4 className="text-2xl font-semibold">Our Plans</h4>
                  </div>
                  <div className="flex-none flex items-center justify-center sm:justify-end space-x-2 py-3 rounded sm:bg-transparent pl-2">
                    <a
                      href="#"
                      className="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 text-sm border-blue-700 bg-blue-700 text-white hover:text-white hover:bg-blue-600 hover:border-blue-600 focus:ring focus:ring-blue-400 focus:ring-opacity-50 active:bg-blue-700 active:border-blue-700 dark:focus:ring-blue-400 dark:focus:ring-opacity-90"
                    >
                      <svg
                        className="hi-mini hi-plus inline-block w-5 h-5 sm:opacity-50"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                      </svg>
                      <span className="hidden sm:inline-block">Sign Up</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* END Page Heading */}

            {/* Page Section */}
            <div className="-mt-28 lg:-mt-32 container xl:max-w-7xl mx-auto p-4 lg:p-8">
              <div className="m-10 lg:p-6 bg-white shadow-sm rounded-lg dark:bg-gray-900">
                <div className=" justify-center rounded-xl bg-gray-50 border-2 border-dashed border-gray-200 text-gray-400 py-4 dark:bg-gray-800 dark:border-gray-700">
                  <div className="bold-500 m-4">
                    <img
                      src={CelebrateIcon}
                      alt="Login successful"
                      className="success-icon"
                    />{" "}
                    <span className={"p-4 text-3xl text-gray-800"}>
                      Choose a Plan that works for you!
                    </span>
                    <DragAndDrop />
                  </div>
                </div>
                {/*

              ADD YOUR MAIN CONTENT ABOVE

              */}
              </div>
            </div>
            {/* END Page Section */}
          </main>
        </div>
        {/* END Page Container */}
      </>
    </>
  );
}
