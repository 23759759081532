import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { FaUserGear } from "react-icons/fa6";
export default function Draganddrop() {
  let navigate = useNavigate();

  const [widgets, setWidgets] = useState<string[]>([]);
  async function domynav() {
    navigate("/");
  }

  async function handleOnDrag(e: React.DragEvent, widgetType: string) {
    e.dataTransfer.setData("widgetType", widgetType);
  }

  async function handleOnDrop(e: React.DragEvent) {
    const widgetType = e.dataTransfer.getData("widgetType") as string;
    console.log("dropped widget " + widgetType);
    setWidgets([...widgets, widgetType]);
  }

  async function handleDragOver(e: React.DragEvent) {
    e.preventDefault();
    console.log("handle drag over ");
  }

  //
  return (
    <>
      <div
        id=""
        className="relative mx-auto flex w-full flex-col dark:bg-gray-900 dark:text-gray-100 m-10"
      >
        {/* Page Content */}
        <main id="" className="flex max-w-full flex-auto flex-col">
          <div className="bg-opacity-95">
            <div className="space-y-2 dark:text-gray-100">
              <div className="font-medium">Choose and Save!</div>
              <div className="grid grid-cols-1 gap-2 bg-blue-900 ">
                <label className="group flex relative">
                  <input
                    type="radio"
                    className="peer appearance-none absolute top-0 left-0 opacity-0"
                    id="radio_group1_a"
                    name="radio_group1"
                    defaultChecked
                  />
                  <span className="transition cursor-pointer opacity-0 scale-50 flex items-center absolute top-0 right-0 h-14 px-4 z-1 text-blue-600 peer-checked:scale-100 peer-checked:opacity-100">
                    <svg
                      className="hi-mini hi-check-circle inline-block w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span className="grow relative flex p-4 border rounded-lg cursor-pointer group-hover:border-blue-500 peer-checked:border-blue-400 peer-checked:bg-blue-50 peer-focus:ring peer-focus:ring-blue-100 dark:border-gray-700 dark:group-hover:border-blue-600 dark:peer-checked:bg-blue-800 dark:peer-focus:ring-blue-900 dark:peer-checked:bg-opacity-10 dark:peer-checked:border-blue-600">
                    <span className="grow pr-8">
                      <span className="block font-bold">
                        Silver Plan - 5 hours per month
                      </span>
                      <span className="block mt-2 pt-3 -mr-8 space-y-1.5 border-t border-gray-500/10 text-gray-600 dark:text-gray-400 text-sm">
                        <span className="font-bold"></span> Visit anytime and
                        earn points toward free coffee and snacks
                      </span>
                    </span>
                  </span>
                </label>
                <label className="group flex relative">
                  <input
                    type="radio"
                    className="peer appearance-none absolute top-0 left-0 opacity-0"
                    id="radio_group1_b"
                    name="radio_group1"
                  />
                  <span className="transition cursor-pointer opacity-0 scale-50 flex items-center absolute top-0 right-0 h-14 px-4 z-1 text-blue-600 peer-checked:scale-100 peer-checked:opacity-100">
                    <svg
                      className="hi-mini hi-check-circle inline-block w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span className="grow relative flex p-4 border rounded-lg cursor-pointer group-hover:border-blue-500 peer-checked:border-blue-400 peer-checked:bg-blue-50 peer-focus:ring peer-focus:ring-blue-100 dark:border-gray-700 dark:group-hover:border-blue-600 dark:peer-checked:bg-blue-800 dark:peer-focus:ring-blue-900 dark:peer-checked:bg-opacity-10 dark:peer-checked:border-blue-600">
                    <span className="grow pr-8">
                      <span className="block font-bold">
                        Gold - 10 hours per month
                      </span>
                      <span className="block mt-2 pt-3 -mr-8 space-y-1.5 border-t border-gray-500/10 text-gray-600 dark:text-gray-400 text-sm">
                        <span className="font-bold"></span>Earn Points
                      </span>
                    </span>
                  </span>
                </label>
                <label className="group flex relative">
                  <input
                    type="radio"
                    className="peer appearance-none absolute top-0 left-0 opacity-0"
                    id="radio_group1_c"
                    name="radio_group1"
                  />
                  <span className="transition cursor-pointer opacity-0 scale-50 flex items-center absolute top-0 right-0 h-14 px-4 z-1 text-blue-600 peer-checked:scale-100 peer-checked:opacity-100">
                    <svg
                      className="hi-mini hi-check-circle inline-block w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span className="grow relative flex p-4 border rounded-lg cursor-pointer group-hover:border-blue-500 peer-checked:border-blue-400 peer-checked:bg-blue-50 peer-focus:ring peer-focus:ring-blue-100 dark:border-gray-700 dark:group-hover:border-blue-600 dark:peer-checked:bg-blue-800 dark:peer-focus:ring-blue-900 dark:peer-checked:bg-opacity-10 dark:peer-checked:border-blue-600">
                    <span className="grow pr-8">
                      <span className="block font-bold">
                        Platinum - 20 hours per month
                      </span>
                      <span className="block mt-2 pt-3 -mr-8 space-y-1.5 border-t border-gray-500/10 text-gray-600 dark:text-gray-400 text-sm">
                        <span className="font-bold">2</span>Earn Points
                      </span>
                    </span>
                  </span>
                </label>
                <label className="group flex relative">
                  <input
                    type="radio"
                    className="peer appearance-none absolute top-0 left-0 opacity-0"
                    id="radio_group1_d"
                    name="radio_group1"
                  />
                  <span className="transition cursor-pointer opacity-0 scale-50 flex items-center absolute top-0 right-0 h-14 px-4 z-1 text-blue-600 peer-checked:scale-100 peer-checked:opacity-100">
                    <svg
                      className="hi-mini hi-check-circle inline-block w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span className="grow relative flex p-4 border rounded-lg cursor-pointer group-hover:border-blue-500 peer-checked:border-blue-400 peer-checked:bg-blue-50 peer-focus:ring peer-focus:ring-blue-100 dark:border-gray-700 dark:group-hover:border-blue-600 dark:peer-checked:bg-blue-800 dark:peer-focus:ring-blue-900 dark:peer-checked:bg-opacity-10 dark:peer-checked:border-blue-600">
                    <span className="grow pr-8">
                      <span className="block font-bold">Unlimited </span>
                      <span className="block text-red-800 mt-2 pt-3 -mr-8 space-y-1.5 border-t border-gray-500/10 text-gray-600 dark:text-gray-400 text-sm">
                        Access all our amenities like Free pillow, cucumber water and blankets.
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="container mx-auto lg:py-32 xl:max-w-7xl flex-row pt-10">
            <div className="flex flex-col space-y-16 lg:flex-row lg:items-center lg:space-x-16 lg:space-y-0">
              <div className="lg:w-2/5">
                <div
                  className="widget m-4 border-2 text-center text-sm font-bold uppercase tracking-wider text-blue-300 bg-orange-100"
                  draggable
                  onDragStart={(x) => {
                    handleOnDrag(x, "🤪");
                  }}
                >
                  🤪 Comfort
                </div>
                <div
                  className="widget m-4 text-center border-2 text-sm font-bold uppercase tracking-wider text-blue-300 bg-orange-100"
                  draggable
                  onDragStart={(x) => {
                    handleOnDrag(x, "😎");
                  }}
                >
                  😎 Silence
                </div>
                <div
                  className="widget m-4 text-center border-2 text-sm font-bold uppercase tracking-wider text-blue-300 bg-orange-100"
                  draggable
                  onDragStart={(x) => {
                    handleOnDrag(x, "😍");
                  }}
                >
                  😍 Location
                </div>
                <div
                  className="widget m-4 text-center border-2 text-sm font-bold uppercase tracking-wider text-blue-300 bg-orange-100"
                  draggable
                  onDragStart={(x) => {
                    handleOnDrag(x, "😄");
                  }}
                >
                  😄 Coffee!
                </div>
              </div>
            </div>
            <div>Drag the icon above to your personality box</div>
            <div
              className={"pagename w-full bg-red-200 flex h-28"}
              onDrop={handleOnDrop}
              onDragOver={handleDragOver}
            >
              {widgets.map((widg, index) => (
                <div
                  key={index}
                  className={
                    "dropped-widget bg-pink-300 rounded max-w-max justify-center m-4 max-h-max p-4 text-3xl"
                  }
                  draggable
                >
                  {widg}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
