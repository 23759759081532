export default function Errors404() {
  return (
    <>
      {/* Pages: Errors: 404 */}

      {/* Page Container */}
      <div
        id=""
        className="text-black flex flex-col mx-auto mt-5 w-full min-h-screen min-w-[320px] bg-gray-100 dark:bg-gray-900 z-1 "
      >
        {/* Page Content */}
        <main
          id="page-content"
          className="flex flex-auto flex-col max-w-full mt-5"
        >
          <div className="relative flex items-center min-h-screen bg-white overflow-hidden dark:bg-gray-800">
            {/* Left/Right Background */}
            <div
              className="absolute left-0 top-0 bottom-0 w-48 bg-rose-50 transform md:skew-x-6 -ml-44 md:-ml-28 dark:bg-rose-500 dark:bg-opacity-10"
              aria-hidden="true"
            />
            <div
              className="absolute right-0 top-0 bottom-0 w-48 bg-rose-50 transform md:skew-x-6 -mr-44 md:-mr-28 dark:bg-rose-500 dark:bg-opacity-10"
              aria-hidden="true"
            />
            {/* END Left/Right Background */}

            {/* Error Content */}
            <div className="relative text-center space-y-16 container xl:max-w-7xl mx-auto px-8 py-16 lg:py-32">
              <div>
                <div className="mb-5 text-rose-300 dark:text-rose-300/50"></div>
                <div className="flex justify-center text-6xl md:text-7xl font-extrabold text-rose-600 dark:text-rose-500">
                  404
                </div>
                <div
                  className="w-12 h-1.5 bg-gray-200 rounded-lg mx-auto my-6 md:my-10 dark:bg-gray-700"
                  aria-hidden="true"
                />
                <h1 className="text-2xl md:text-3xl font-extrabold mb-3">
                  Well, This is Awkward...
                </h1>
                <h2 className="md:leading-relaxed font-medium lg:w-3/5 mx-auto mb-5 dark:text-gray-400">
                  Looks like we can't find the page you're looking for. Maybe
                  it's been moved or deleted. Sorry about that! Please try
                  searching our web application:
                </h2>
                <form className="space-y-1 max-w-sm mx-auto">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 w-12 my-px ml-px flex items-center justify-center pointer-events-none rounded-l-lg text-black dark:text-gray-400"></div>
                    <input
                      type="text"
                      id="search"
                      name="search"
                      placeholder="Search.."
                      className="w-full block border placeholder-gray-500 pl-12 pr-5 py-3 leading-6 rounded-lg border-gray-200 focus:border-rose-500 focus:ring focus:ring-rose-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-rose-500 dark:placeholder-gray-400"
                    />
                  </div>
                </form>
              </div>
              <a
                href="/"
                className="group inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 text-sm border-gray-200 bg-white text-black hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
              >
                <span>Back to Dashboard</span>
              </a>
            </div>
            {/* END Error Content */}
          </div>
        </main>
        {/* END Page Content */}
      </div>
      {/* END Page Container */}

      {/* END Pages: Errors: 404 */}
    </>
  );
}
